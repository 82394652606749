.react-tel-input .selected-flag {
	padding: 0 0 0 15px !important;
}

.eath-style {
	width: 25px;
	height: 20px;
	background-image: url('/src/assets/eath-icon.svg');
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 50%;
	margin-top: -10px;
}

.form-control {
	font-family: 'Montserrat' !important;
}
.form-control:hover {
	border-color: #7f7f84 !important;
}

.form-control:active {
	border-color: #5222d0 !important;
}
.form-control:focus {
	box-shadow: none !important;
	border-color: #5222d0 !important;
}

.country-list {
	font-family: 'Montserrat' !important;
	box-shadow: none !important;
	/* margin: 10px 0px 10px 0px !important; */
	border: 1px solid #dbdbdb !important;
}
.country-list .flag {
	top: 4px !important;
}
.country {
	font-family: 'Montserrat' !important;
	padding: 6px 9px 6px 46px !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: space-between !important;
}

.country:hover {
	background-color: rgba(82, 34, 208, 0.1) !important;
}
